import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {
        status: {
            type: Boolean
        }
    },
    computed: {
        ...mapGetters({
            rules: "filials/rules",
            model: "filials/model",
            columns: "filials/columns",
            regions: 'region/inventory',
            filtered_city_district: 'region/filtered_city_district',
        }),
    },
    data(){
        return {
            loadingData: false,
            dialogImageUrl: "",
            dialogVisible: false,
            selectedPosition: null,
            updateImage: [],
        }
    },
    created(){
        this.getRegions({protected_key: 'clinic'});
    },
    methods: {
        ...mapActions({
            empty: 'filials/empty',
            getRegions: 'region/inventory',
            getCityDistricts: 'region/filteredCityDistrict',
        }),
        submit(close = true) {
            let formData = new FormData();

            formData.append('clinic_id', parseInt(this.$route.params.id));

            for (const key in this.form) {
                if (this.form[key]) {
                    formData.append(key, this.form[key]);
                }else {
                    formData.append('', this.form[key]);
                }
            }

            /**
                 * Get Uplaod Images
                 */
             if (_.size(this.updateImage) > 0) {
                for (const key in this.updateImage) {
                    if (this.updateImage.hasOwnProperty(key)) {
                        const element = this.updateImage[key];
                        formData.append(`images[${key}]`, element);
                    }
                }
            }

            let items = this.items.map(item => {
                return {
                    r_s_bank: item.r_s_bank,
                    mfo: item.mfo,
                    currency_id: item.currency_id,
                }
            });
            this.buildFormData(formData, items, 'items');

            let building_informations = this.building_informations.map(item => {
                return {
                    cadastre_number: item.cadastre_number,
                    property_ownership_id: item.property_ownership_id,
                    document_name: item.document_name,
                    document_number: item.document_number,
                    document_date: item.document_date,
                    fayls: item.fayls
                }
            });
            this.buildFormData(formData, building_informations, 'building_informations');
            this.buildFormData(formData, this.old_building_informations, 'old_building_informations');
            this.buildFormData(formData, this.old_filial_items, 'old_filial_items');
            
            this.$refs["form"].validate((valid) => {
                if (valid && this.validateUplaodImages()) {
                    this.loadingButton = true;   
                    this.save(formData)
                        .then((res) => {
                            this.loadingButton = false;
                            this.empty();
                            this.items = [];
                            this.building_informations = [];
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
        buildFormData(formData, data, parentKey) {
            if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
              Object.keys(data).forEach(key => {
                this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
              });
            } else {
              const value = data == null ? '' : data;

              formData.append(parentKey, value);
            }
        },
        validateUplaodImages() {
            let has_status = false;
            let extensions = ['jpeg', 'jpg', 'png', 'svg', 'pdf', 'doc', 'docx', 'xls', 'xlsx']; //  
            let errors = [];

            // Validation for doctor image
            if (_.find(this.updateImage, function(o) { return (o.type != "image/jpeg" && o.type != "image/png") })) {
                this.$message({
                    message: this.$t('message.The image format must be JPEG, JPG, PNG'),
                    type: 'warning',
                    showClose: true
                });
                return false;
            }

            function clean_code(elem){
                elem.fayls.forEach(item => {
                    let ext = _.split(item.name, '.', 2);
                    let has_ext = extensions.includes(ext[1]);
                    if (!has_ext) {
                        if (!errors.includes(_.upperCase(ext[1]))) {
                            errors.push(_.upperCase(ext[1]));
                        }
                    }
                }); 
            }
           
            this.building_informations.forEach(elem => {
                clean_code(elem);
            });

            if (!_.isEmpty(errors)) {
                const h = this.$createElement;
                let mistake = errors.map(mime => h('li', `${mime}` + '  kengaytmali fayl yuklay olmaysiz'));
                const message = h('ul', mistake);
                    this.$message({
                        message: message,
                        type: 'warning',
                        showClose: true
                    });
                has_status = false;     
            }
            else{
                has_status = true;
            }

            return (has_status) ? true : false;   
        },
        add() {
            this.items.push({
                r_s_bank: "",
                mfo: "",
                currency_id: "",
            });
        },
        deleteItem(index) {
            delete this.items.splice(index, 1);
        },

        add2() {
            this.building_informations.push({
                cadastre_number: "",
                property_ownership_id: null,
                document_name: "",
                document_number: "",
                document_date: "",
                fayls: []
            });
        },
        deleteItem2(index) {
            delete this.building_informations.splice(index, 1);
        },

        updateImageList(file) {
            this.updateImage.push(file.raw);  
        },
        handleRemove(file) {
            if (file.id) {
                this.deleteImage({ product_id: this.product ? this.product.id : null, image_id: file.id }).then(res => {
                    this.$alert(res);
                }).catch(err => {});
            } else {
                for (var i = 0; i < _.size(this.updateImage); i++) {
                    if (this.updateImage[i].name === file.name) {
                        this.updateImage.splice(i, 1);
                    }
                }

            }
        },

         /** Clinic Building Files */
        getItemPosition(id, fileStatus) {
            this.selectedPosition = id;
            this.fileStatus = fileStatus;
        },

        updateBuildingInformationFile(file){
            let id = this.selectedPosition;
            if (!this.status) {
                if (!this.fileStatus) {
                    this.old_building_informations.forEach(item => {
                        if (item.id == id) {
                            item.fayls.push(file.raw);
                        }
                    });                
                }else{
                    this.building_informations.forEach(item => {
                        if (item.id == id) {
                            item.fayls.push(file.raw);
                        }
                    });  
                }
            }else{
                this.building_informations.forEach(item => {
                    if (item.id == id) {
                        item.fayls.push(file.raw);
                    }
                }); 
            }
        },
        handleBuildingInformationFileRemoveOld(file){
            for (let i = 0; i < _.size(this.old_building_informations); i++) {
                for (var j = 0; j < _.size(this.old_building_informations[i].fayls); j++) {
                    if (this.old_building_informations[i].fayls[j].name === file.name) { 
                        this.old_building_informations[i].fayls.splice(j, 1);
                    }
                }
            }
        },
        handleBuildingInformationFileRemove(file){
            for (let i = 0; i < _.size(this.building_informations); i++) {
                for (var j = 0; j < _.size(this.building_informations[i].fayls); j++) {
                    if (this.building_informations[i].fayls[j].name === file.name) { 
                        this.building_informations[i].fayls.splice(j, 1);
                    }
                }
            }
        },
        /** Clinic Building Files */

        selectedRegionId(region_id){ 
            
            if(this.form.city_district_id) {
                this.form.city_district_id = null;
            }

            if (region_id) {     
                let query = {region_id: region_id};
                this.getCityDistricts(query)
                .then((result) => {
                    
                }).catch((err) => {
                    console.log(err); 
                });
            }else{
                this.$store.commit('region/EMPTY_CITY_DISTRICT');
            }
        },
    }
}