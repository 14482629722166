<template >
    <div>
        <div class="modal-header">
            <div class="modal-title-my">
                {{
                    $t("message.new_c", {
                        m: $t("message.filials"),
                    })
                }}
            </div>
            <div>
                <crm-store-update-close
                    :button_type="'store'"
                    :loading="loadingButton"
                    @c-submit="submit(true)"
                    @c-close="close()"
                ></crm-store-update-close>
            </div>
        </div>
        <div class="bodal-body-my">
            <el-form ref="form" status-icon :model="form" :rules="rules">
                <el-row :gutter="20">
                    <el-divider content-position="left">
                        Klinika ma'lumotlarni kirtish
                    </el-divider>

                    <el-col :span="12">
                        <el-form-item
                            :label="$t('message.myNameClick')"
                            prop="legal_entity_name"
                            class="label_mini"
                        >
                            <crm-input
                                :size="'medium'"
                                :inputValue="form.legal_entity_name"
                                v-model="form.legal_entity_name"
                                :placeholder="$t('message.myNameClick')"
                            ></crm-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item
                            :label="$t('message.brand_name')"
                            class="label_mini"
                            prop="brand_name"
                        >
                            <crm-input
                                :size="'medium'"
                                :inputValue="form.brand_name"
                                v-model="form.brand_name"
                                :placeholder="$t('message.brand_name')"
                            ></crm-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item
                            :label="$t('message.medicalOrganizationTypes')"
                            prop="type_id"
                            class="label_mini"
                        >
                            <select-organization-type
                                :id="form.type_id"
                                v-model="form.type_id"
                                :size="'medium'"
                            >
                            </select-organization-type>
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item
                            :label="$t('message.email')"
                            class="label_mini"
                            prop="email"
                        >
                            <crm-input
                                :size="'medium'"
                                :inputValue="form.email"
                                v-model="form.email"
                                :placeholder="$t('message.email')"
                            ></crm-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="Telefon raqami" prop="phone_number">
                            <crm-input
                                :size="'medium'"
                                v-model="form.phone_number"
                                :inputValue="form.phone_number"
                                :placeholder="'+998999999999'"
                                :maskFormat="'tel'"
                            ></crm-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="6">
                        <el-form-item
                            :label="$t('message.regions')"
                            class="label_mini"
                            prop="region_id"
                        >
                            <el-select
                                class="w-100"
                                v-model="form.region_id"
                                @change="selectedRegionId"
                                placeholder="Viloyat"
                                filterable
                                clearable
                            >
                                <el-option
                                    v-for="(region, index) in regions"
                                    :key="'region-' + index"
                                    :label="region.region_name"
                                    :value="region.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="6">
                        <el-form-item
                            :label="$t('message.cityDistricts')"
                            class="label_mini"
                            prop="city_district_id"
                        >
                            <el-select
                                class="w-100"
                                v-model="form.city_district_id"
                                placeholder="Shahar va Tuman"
                                filterable
                                clearable
                            >
                                <el-option
                                    v-for="(
                                        city, index
                                    ) in filtered_city_district"
                                    :key="'city-' + index"
                                    :label="city.name"
                                    :value="city.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="6">
                            <el-form-item
                            :label="$t('message.time_of_registered')"
                            class="label_mini"
                            >
                                <el-date-picker
                                    type="date"
                                    :format="date_format"
                                    :value-format="date_format"
                                    :placeholder="$t('message.time_of_registered')"
                                    v-model="form.time_of_registered"
                                    style="width: 100%"
                                ></el-date-picker>
                            </el-form-item>
                    </el-col>

                    <el-col :span="6">
                        <el-form-item
                            :label="$t('message.address')"
                            class="label_mini"
                        >
                            <el-input
                                type="textarea"
                                :rows="2"
                                :inputValue="form.address"
                                v-model="form.address"
                                :placeholder="$t('message.address')"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                
                    <el-col :span="8">
                        <div class="imgUpload">
                            <el-upload
                                class="upload-demo w-100"
                                action="/"
                                :limit="1"
                                :on-change="updateImageList"
                                :on-remove="handleRemove"
                                accept="image/*"
                                name="image"
                                list-type="picture-card"
                                :auto-upload="false"
                            >
                                <i slot="default" class="el-icon-plus"></i>
                            </el-upload>
                        </div>
                    </el-col>
                </el-row>

                <div>
                    <el-divider content-position="left">
                        Bank Malumotlari.
                    </el-divider>
                    <el-row
                        :gutter="20"
                        v-for="(item, index) in items"
                        :key="index"
                    >
                        <el-col :span="8">
                            <el-form-item
                                :label="$t('message.r_s_bank')"
                                class="label_mini"
                            >
                                <crm-input
                                    :size="'medium'"
                                    :inputValue="item.r_s_bank"
                                    v-model="item.r_s_bank"
                                    :placeholder="$t('message.r_s_bank')"
                                ></crm-input>
                            </el-form-item>
                        </el-col>
                        <!-- end  col -->

                        <el-col :span="8">
                            <el-form-item
                                :label="$t('message.mfo')"
                                class="label_mini"
                            >
                                <crm-input
                                    :size="'medium'"
                                    :inputValue="item.mfo"
                                    v-model="item.mfo"
                                    :placeholder="$t('message.mfo')"
                                ></crm-input>
                            </el-form-item>
                        </el-col>
                        <!-- end  col -->

                        <el-col :span="8">
                            <el-form-item
                                :label="$t('message.currencyTypes')"
                                class="label_mini"
                            >
                                <select-currency-type
                                    :id="item.currency_id"
                                    v-model="item.currency_id"
                                    :size="'medium'"
                                >
                                </select-currency-type>
                            </el-form-item>
                        </el-col>
                        <!-- end  col -->
                        <i
                            class="delet-icon el-icon-circle-close"
                            @click="deleteItem(index)"
                        ></i>
                    </el-row>

                    <div class="w-100">
                        <div class="add-form-educet">
                            <el-button
                                class="asosy-btn-d"
                                @click="add"
                                icon="el-icon-plus"
                            >
                                Qo'shish
                            </el-button>
                        </div>
                    </div>
                </div>

                <div>
                    <el-divider content-position="left">
                        Bino inshoat ma'lumotlarni
                    </el-divider>

                    <el-row
                        :gutter="20"
                        v-for="(item, index) in building_informations"
                        :key="index"
                    >
                        <el-col :span="8">
                            <el-form-item
                                :label="$t('message.cadastre_number')"
                                class="label_mini"
                            >
                                <crm-input
                                    :size="'medium'"
                                    :inputValue="item.cadastre_number"
                                    v-model="item.cadastre_number"
                                    :placeholder="$t('message.cadastre_number')"
                                ></crm-input>
                            </el-form-item>
                        </el-col>
                        <!-- end  col -->

                        <el-col :span="8">
                            <el-form-item
                                :label="$t('message.propertyOwnerships')"
                                prop="name"
                                class="label_mini"
                            >
                                <select-property-ownerships
                                    :id="item.property_ownership_id"
                                    v-model="item.property_ownership_id"
                                    :size="'medium'"
                                >
                                </select-property-ownerships>
                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item
                                :label="$t('message.document_name')"
                                class="label_mini"
                            >
                                <crm-input
                                    :size="'medium'"
                                    :inputValue="item.document_name"
                                    v-model="item.document_name"
                                    :placeholder="$t('message.document_name')"
                                ></crm-input>
                            </el-form-item>
                        </el-col>
                        <!-- end  col -->

                        <el-col :span="8">
                            <el-form-item
                                :label="$t('message.document_number')"
                                class="label_mini"
                            >
                                <crm-input
                                    :size="'medium'"
                                    :inputValue="item.document_number"
                                    v-model="item.document_number"
                                    :placeholder="$t('message.document_number')"
                                ></crm-input>
                            </el-form-item>
                        </el-col>
                        <!-- end  col -->

                        <el-col :span="8">
                            <el-form-item
                                :label="$t('message.document_date')"
                                class="label_mini"
                            >
                                <el-date-picker
                                    v-model="item.document_date"
                                    type="date"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    class="w-100"
                                    :placeholder="$t('message.document_date')"
                                    :size="'medium'"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <!-- end  col -->

                        <el-col :span="12">
                            <div class="imgUpload">
                                <el-upload
                                    class="upload-demo"
                                    action="/"
                                    :limit="3"
                                    accept="file/*"
                                    name="file"
                                    multiple
                                    :file-list="item.fayls"
                                    @input.native="getItemPosition(item.id)"
                                    :auto-upload="false"
                                    :on-change="updateBuildingInformationFile"
                                    :on-remove="
                                        handleBuildingInformationFileRemove
                                    "
                                    list-type="text"
                                >
                                    <el-button size="small" type="primary"
                                        >Click to upload</el-button
                                    >
                                    <div slot="tip" class="el-upload__tip">
                                       jpg, jpeg, png, svg, pdf, docx, xlsx formatdagi fayllarini yuklash mumkun 5 mb katta bo'lmagan
                                    </div>
                                </el-upload>
                            </div>
                        </el-col>

                        <i
                            class="delet-icon el-icon-circle-close"
                            @click="deleteItem2(index)"
                        ></i>
                    </el-row>

                    <div class="w-100">
                        <div class="add-form-educet">
                            <el-button
                                class="asosy-btn-d"
                                @click="add2"
                                icon="el-icon-plus"
                            >
                                Qo'shish
                            </el-button>
                        </div>
                    </div>
                </div>
            </el-form>
        </div>
        <!-- end app-modal__body -->
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import clinicFilial from "@/utils/mixins/models/clinicFilial";

export default {
    mixins: [form, drawer, clinicFilial],
    data() {
        return {
            items: [
                {
                    r_s_bank: "",
                    mfo: "",
                    currency_id: "",
                },
            ],
            building_informations: [
                {
                    cadastre_number: "",
                    property_ownership_id: null,
                    document_name: "",
                    document_number: "",
                    document_date: "",
                    fayls: [],
                },
            ],
        };
    },
    methods: {
        ...mapActions({
            save: "filials/store",
        }),
        afterOpen(){
            this.form = JSON.parse(JSON.stringify(this.model));
        },
        afterLeave(){
            this.$store.commit('filials/EMPTY_MODEL');
            this.items = [
                {
                    r_s_bank: "",
                    mfo: "",
                    currency_id: "",
                },
            ],
            this.building_informations = [
                {
                    cadastre_number: "",
                    property_ownership_id: null,
                    document_name: "",
                    document_number: "",
                    document_date: "",
                    fayls: [],
                },
            ]  
        }
    },
};
</script>
<style lang="scss">
.delet-icon {
    font-size: 30px;
    position: absolute;
    right: -24px;
    top: 35px;
    color: red;
}
</style>
