<template >
    <div>
        <div class="modal-header">
            <div class="modal-title-my">
                {{
                    $t("message.new_u", {
                        m: $t("message.clinics"),
                    })
                }}
            </div>
            <div>
                <crm-store-update-close
                    :button_type="'store'"
                    :loading="loadingButton"
                    @c-submit="submit(true)"
                    @c-close="close()"
                ></crm-store-update-close>
            </div>
        </div>
        <div class="bodal-body-my" v-loading="loadingData">
            <el-form ref="form" status-icon :model="form" :rules="filtered_rules">
                  <el-row :gutter="20">
                    <el-divider content-position="left">
                        Klinika ma'lumotlarni kirtish
                    </el-divider>

                    <el-col :span="6">
                        <el-form-item label="Tibbiy muassasa">
                            <el-select
                                v-model="form.clinic_id"
                                filterable
                                clearable
                                remote
                                :placeholder="$t('message.clinics')"
                                :remote-method="remoteMethod"
                                @change="selectedClinicId"
                                :loading="loading"
                                size="large"
                                class="w-100">
                                <el-option
                                v-for="item in clinics"
                                :key="item.id"
                                :label="item.legal_entity_name"
                                :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="6">
                        <el-form-item
                            :label="$t('message.organizational_form')"
                            class="label_mini"
                            prop="clinic_type"
                        >
                           <el-select class=" w-100" v-model="form.clinic_type" filterable clearable  :placeholder="$t('message.organizational_form')" size="medium">
                                <el-option :label="$t('message.state_clinic')" :value="true"></el-option>
                                <el-option :label="$t('message.private_clinic')" :value="false"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            :label="$t('message.myNameClick')"
                            prop="legal_entity_name"
                            class="label_mini"
                        >
                            <crm-input
                                :size="'medium'"
                                :inputValue="form.legal_entity_name"
                                v-model="form.legal_entity_name"
                                :placeholder="$t('message.myNameClick')"
                            ></crm-input>
                        </el-form-item>
                        <el-form-item label="Telefon raqami" prop="phone_number">
                            <crm-input
                                :size="'medium'"
                                v-model="form.phone_number"
                                :inputValue="form.phone_number"
                                :placeholder="'+998999999999'"
                                :maskFormat="'tel'"
                            ></crm-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="6">
                        <el-form-item
                            :label="$t('message.brand_name')"
                            class="label_mini"
                            prop="brand_name"
                        >
                            <crm-input
                                :size="'medium'"
                                :inputValue="form.brand_name"
                                v-model="form.brand_name"
                                :placeholder="$t('message.brand_name')"
                            ></crm-input>
                        </el-form-item>
                        <el-form-item
                            :label="$t('message.medicalOrganizationTypes')"
                            prop="type_id"
                            class="label_mini"
                        >
                            <select-organization-type
                                :id="form.type_id"
                                v-model="form.type_id"
                                :size="'medium'"
                            >
                            </select-organization-type>
                        </el-form-item>
                        <el-form-item
                            :label="$t('message.email')"
                            class="label_mini"
                            prop="email"
                        >
                            <crm-input
                                :size="'medium'"
                                :inputValue="form.email"
                                v-model="form.email"
                                :placeholder="$t('message.email')"
                            ></crm-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="6">
                       <div class="imgUpload" style="margin-bottom: 15px">
                            <el-upload class="upload-demo w-100" action="/" :limit="1" :on-change="updateImageList" :on-remove="handleRemove" 
                                accept="image/*" name="image" list-type="picture-card" :auto-upload="false" ref="img_upload">
                                <i slot="default" class="el-icon-plus"></i>
                            </el-upload>
                        </div>
                        <el-form-item
                            :label="$t('message.inn')"
                            class="label_mini"
                            prop="inn"
                            v-show="!visible_inn"
                        >
                            <el-input
                                size="medium"
                                placeholder="123456789"
                                v-model="form.inn"
                                v-mask="'#########'"
                                clearable
                                @input="filterINN"
                                @change="clearData"
                            >
                            </el-input>
                            <!-- <crm-input
                                :size="'medium'"
                                :inputValue="form.inn"
                                v-model="form.inn"
                                :placeholder="'123456789'"
                                :maskFormat="'inn'"
                            ></crm-input> -->
                        </el-form-item>
                    </el-col>
                        <div v-if="isShow" class="result_inn">
                            <el-table :data="likely_inns"  :row-class-name="tableRowClassName">
                                <el-table-column label="Tibbiy muassasa" width="350">
                                    <template slot-scope="item">
                                        <span>{{item.row.legal_entity_name}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Inn raqam">
                                    <template slot-scope="item">
                                        {{ item.row.inn }}
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                </el-row>
                
                  <div>
                    <el-divider content-position="left">
                        Klinikaning yuridik manzili
                    </el-divider> 
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <el-form-item
                            :label="$t('message.regions')"
                            class="label_mini"
                            prop="region_id"
                        >
                            <el-select  class="w-100"  v-model="form.region_id" @change="selectedRegionId(form.region_id, true)" placeholder="Viloyat" filterable clearable>
                                <el-option  v-for="(region,index) in legal_regions" :key="'legal_regions-'+index"
                                    :label="region.region_name"
                                    :value="region.id"
                                ></el-option>
                            </el-select>
                            </el-form-item>
                        </el-col>

                        <el-col :span="6">
                            <el-form-item
                            :label="$t('message.cityDistricts')"
                            class="label_mini"
                            prop="city_district_id"
                        >
                            <el-select  class="w-100"  v-model="form.city_district_id" placeholder="Shahar va Tuman" filterable clearable>
                                <el-option  v-for="(city,index) in city_districts" :key="'city-'+index"
                                    :label="city.name"
                                    :value="city.id"
                                ></el-option>
                            </el-select>
                            </el-form-item>
                        </el-col>
                         <el-col :span="6">
                            <el-form-item
                            :label="$t('message.time_of_registered')"
                            class="label_mini"
                            >
                                <el-date-picker
                                    type="date"
                                    :format="date_format"
                                    :value-format="date_format"
                                    :placeholder="$t('message.time_of_registered')"
                                    v-model="form.time_of_registered"
                                    style="width: 100%"
                                ></el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item
                            :label="$t('message.address')"
                            class="label_mini"
                        >
                            <el-input
                                type="textarea"
                                :rows="2"
                                :inputValue="form.address"
                                v-model="form.address"
                                :placeholder="$t('message.address')"
                            ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>

                 <div>
                    <el-divider content-position="left">
                        Klinikaning faoliyat manzili
                    </el-divider> 
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <el-form-item
                            :label="$t('message.regions')"
                            class="label_mini"
                            prop="region_id"
                        >
                            <el-select  class="w-100"  v-model="form.work_region_id" @change="selectedRegionId(form.work_region_id, false)" placeholder="Viloyat" filterable clearable>
                                <el-option  v-for="(region,index) in regions" :key="'region-'+index"
                                    :label="region.region_name"
                                    :value="region.id"
                                ></el-option>
                            </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item
                            :label="$t('message.cityDistricts')"
                            class="label_mini"
                            prop="city_district_id"
                        >
                            <el-select  class="w-100"  v-model="form.work_city_id" placeholder="Shahar va Tuman" filterable clearable>
                                <el-option  v-for="(city,index) in work_city_districts" :key="'city-'+index"
                                    :label="city.name"
                                    :value="city.id"
                                ></el-option>
                            </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item
                            :label="$t('message.address')"
                            class="label_mini"
                        >
                            <el-input
                                type="textarea"
                                :rows="2"
                                :inputValue="form.work_address"
                                v-model="form.work_address"
                                :placeholder="$t('message.address')"
                            ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>

                <div>
                    <el-divider content-position="left">
                        Bank Malumotlari.
                    </el-divider>

                    <el-row
                        :gutter="20"
                        v-for="(item, index) in old_clinic_items"
                        :key="index"
                    >
                        <el-col :span="6">
                            <el-form-item
                                :label="$t('message.bank_name')"
                                class="label_mini"
                            >
                                <el-input
                                    v-model="item.bank_name"
                                    size="medium"
                                    :placeholder="$t('message.bank_name')"
                                    @keypress.native="isLetter($event)"
                                    maxlength="100"
                                    clearable
                                ></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="6">
                            <el-form-item
                                :label="$t('message.r_s_bank')"
                                class="label_mini"
                            >
                                <crm-input
                                    :size="'medium'"
                                    :inputValue="item.r_s_bank"
                                    v-model="item.r_s_bank"
                                    :placeholder="$t('message.r_s_bank')"
                                    :maskFormat="'r_s_bank'"
                                ></crm-input>
                            </el-form-item>
                        </el-col>
                        <!-- end  col -->

                        <el-col :span="6">
                            <el-form-item
                                :label="$t('message.mfo')"
                                class="label_mini"
                            >
                                <crm-input
                                    :size="'medium'"
                                    :inputValue="item.mfo"
                                    v-model="item.mfo"
                                    :placeholder="'12345'"
                                    :maskFormat="'mfo'"
                                ></crm-input>
                            </el-form-item>
                        </el-col>
                        <!-- end  col -->

                        <el-col :span="6">
                            <el-form-item
                                :label="$t('message.currencyTypes')"
                                class="label_mini"
                            >
                                <select-currency-type
                                    :id="item.currency_id"
                                    v-model="item.currency_id"
                                    :size="'medium'"
                                >
                                </select-currency-type>
                            </el-form-item>
                        </el-col>
                        <!-- end  col -->
                        <!-- <i
                            class="delet-icon el-icon-circle-close"
                            @click="deleteItem(index)"
                        ></i> -->
                    </el-row>

                      <el-row
                        :gutter="20"
                        v-for="(item, index) in items"
                        :key="'item - ' + index"
                    >
                        <el-col :span="8">
                            <el-form-item
                                :label="$t('message.r_s_bank')"
                                class="label_mini"
                            >
                                <crm-input
                                    :size="'medium'"
                                    :inputValue="item.r_s_bank"
                                    v-model="item.r_s_bank"
                                    :placeholder="$t('message.r_s_bank')"
                                ></crm-input>
                            </el-form-item>
                        </el-col>
                        <!-- end  col -->

                        <el-col :span="8">
                            <el-form-item
                                :label="$t('message.mfo')"
                                class="label_mini"
                            >
                                <crm-input
                                    :size="'medium'"
                                    :inputValue="item.mfo"
                                    v-model="item.mfo"
                                    :placeholder="$t('message.mfo')"
                                ></crm-input>
                            </el-form-item>
                        </el-col>
                        <!-- end  col -->

                        <el-col :span="8">
                            <el-form-item
                                :label="$t('message.currencyTypes')"
                                class="label_mini"
                            >
                                <select-currency-type
                                    :id="item.currency_id"
                                    v-model="item.currency_id"
                                    :size="'medium'"
                                >
                                </select-currency-type>
                            </el-form-item>
                        </el-col>
                        <!-- end  col -->
                        <i
                            class="delet-icon el-icon-circle-close"
                            @click="deleteItem(index)"
                        ></i>
                    </el-row>

                    <div class="w-100">
                        <div class="add-form-educet">
                            <el-button
                                class="asosy-btn-d"
                                @click="add"
                                icon="el-icon-plus"
                            >
                                Qo'shish
                            </el-button>
                        </div>
                    </div>
                </div>

                <div>
                    <el-divider content-position="left">
                        Bino inshoat ma'lumotlarni
                    </el-divider>

                    <el-row
                        :gutter="20"
                        v-for="(item, index) in old_building_informations"
                        :key="index + ' -old_building_information'"
                    >
                        <el-col :span="8">
                            <el-form-item
                                :label="$t('message.cadastre_number')"
                                class="label_mini"
                            >
                                <crm-input
                                    v-model="item.cadastre_number"
                                    :inputValue="item.cadastre_number"
                                    :placeholder="$t('message.cadastre_number')"
                                    :size="'medium'"
                                ></crm-input>
                            </el-form-item>
                        </el-col>
                        <!-- end  col -->

                        <el-col :span="8">
                            <el-form-item
                                :label="$t('message.propertyOwnerships')"
                                prop="name"
                                class="label_mini"
                            >
                                <select-property-ownerships
                                    v-model="item.property_ownership_id"
                                    :id="item.property_ownership_id"
                                    :size="'medium'"
                                >
                                </select-property-ownerships>
                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item
                                :label="$t('message.document_name')"
                                class="label_mini"
                            >
                                <crm-input
                                    :size="'medium'"
                                    :inputValue="item.document_name"
                                    v-model="item.document_name"
                                    :placeholder="$t('message.document_name')"
                                ></crm-input>
                            </el-form-item>
                        </el-col>
                        <!-- end  col -->

                        <el-col :span="8">
                            <el-form-item
                                :label="$t('message.document_number')"
                                class="label_mini"
                            >
                                <crm-input
                                    :size="'medium'"
                                    :inputValue="item.document_number"
                                    v-model="item.document_number"
                                    :placeholder="$t('message.document_number')"
                                ></crm-input>
                            </el-form-item>
                        </el-col>
                        <!-- end  col -->

                        <el-col :span="8">
                            <el-form-item
                                :label="$t('message.document_date')"
                                class="label_mini"
                            >
                                <el-date-picker
                                    v-model="item.document_date"
                                    type="date"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    class="w-100"
                                    :placeholder="$t('message.document_date')"
                                    :size="'medium'"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <!-- end  col -->

                        <el-col :span="12">
                            <div class="imgUpload">
                                 <el-upload
                                    class="upload-demo"
                                    action="/"
                                    :limit="3"
                                    accept="file/*"
                                    name="file"
                                    multiple
                                    :file-list="item.fayls"
                                    @input.native="getItemPosition(item.id, false)"
                                    :auto-upload="false"
                                    :on-change="updateBuildingInformationFile"
                                    :on-remove="handleBuildingInformationFileRemoveOld"
                                    list-type="text"
                                >
                                    <el-button size="small" type="primary"
                                        >Click to upload</el-button
                                    >
                                    <div slot="tip" class="el-upload__tip">
                                       jpg, jpeg, png, svg, pdf, docx, xlsx formatdagi fayllarini yuklash mumkun 5 mb katta bo'lmagan
                                    </div>
                                </el-upload>
                            </div>
                        </el-col>

                        <!-- <i v-show="item.id" class="delet-icon el-icon-circle-close"
                            @click="destroyBuildingInformationItem(item)"
                        ></i> -->
                    </el-row>

                    <el-row
                        :gutter="20"
                        v-for="(item, index) in building_informations"
                        :key="index"
                    >
                        <el-col :span="8">
                            <el-form-item
                                :label="$t('message.cadastre_number')"
                                class="label_mini"
                            >
                                <crm-input
                                    :size="'medium'"
                                    :inputValue="item.cadastre_number"
                                    v-model="item.cadastre_number"
                                    :placeholder="$t('message.cadastre_number')"
                                ></crm-input>
                            </el-form-item>
                        </el-col>
                        <!-- end  col -->

                        <el-col :span="8">
                            <el-form-item
                                :label="$t('message.propertyOwnerships')"
                                prop="name"
                                class="label_mini"
                            >
                                <select-property-ownerships
                                    :id="item.property_ownership_id"
                                    v-model="item.property_ownership_id"
                                    :size="'medium'"
                                >
                                </select-property-ownerships>
                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item
                                :label="$t('message.document_name')"
                                class="label_mini"
                            >
                                <crm-input
                                    :size="'medium'"
                                    :inputValue="item.document_name"
                                    v-model="item.document_name"
                                    :placeholder="$t('message.document_name')"
                                ></crm-input>
                            </el-form-item>
                        </el-col>
                        <!-- end  col -->

                        <el-col :span="8">
                            <el-form-item
                                :label="$t('message.document_number')"
                                class="label_mini"
                            >
                                <crm-input
                                    :size="'medium'"
                                    :inputValue="item.document_number"
                                    v-model="item.document_number"
                                    :placeholder="$t('message.document_number')"
                                ></crm-input>
                            </el-form-item>
                        </el-col>
                        <!-- end  col -->

                        <el-col :span="8">
                            <el-form-item
                                :label="$t('message.document_date')"
                                class="label_mini"
                            >
                                <el-date-picker
                                    v-model="item.document_date"
                                    type="datetime"
                                    format="yy-MM-dd HH:mm"
                                    value-format="yy-MM-dd HH:mm"
                                    class="w-100"
                                    :placeholder="$t('message.document_date')"
                                    :size="'medium'"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <!-- end  col -->

                        <el-col :span="12">
                              <div class="imgUpload">
                                 <el-upload
                                    class="upload-demo"
                                    action="/"
                                    :limit="3"
                                    accept="file/*"
                                    name="file"
                                    multiple
                                    :file-list="item.fayls"
                                    @input.native="getItemPosition(item.id, true)"
                                    :auto-upload="false"
                                    :on-change="updateBuildingInformationFile"
                                    :on-remove="
                                        handleBuildingInformationFileRemove
                                    "
                                    list-type="text"
                                >
                                    <el-button size="small" type="primary"
                                        >Click to upload</el-button
                                    >
                                    <div slot="tip" class="el-upload__tip">
                                       jpg, jpeg, png, svg, pdf, docx, xlsx formatdagi fayllarini yuklash mumkun 5 mb katta bo'lmagan
                                    </div>
                                </el-upload>
                            </div>
                        </el-col>

                        <i
                            class="delet-icon el-icon-circle-close"
                            @click="deleteItem2(index)"
                        ></i>
                    </el-row>

                    <div class="w-100">
                        <div class="add-form-educet">
                            <el-button
                                class="asosy-btn-d"
                                @click="add2"
                                icon="el-icon-plus"
                            >
                                Qo'shish
                            </el-button>
                        </div>
                    </div>
                </div>
            </el-form>
        </div>
        <!-- end app-modal__body -->
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import _ from 'lodash';
import drawer from "@/utils/mixins/drawer";
import clinic from "@/utils/mixins/models/clinic";
import form from "@/utils/mixins/form";

export default {
    mixins: [form, drawer, clinic],
     props: {
        selectedItem: {
            type: Object
        }
    },
    data() {
        return {
            old_clinic_items: [],
            old_building_informations: [],
            items: [],
            building_informations: [],
        };
    },
    computed: {
        ...mapGetters({
            building_informations_of_clinics: "clinics/building_informations_of_clinics",
            old_items: "clinics/items"
        }),
    },
    methods: {
        load() {
            if (!this.loadingData) {
                let model_id = this.selectedItem.id;
                this.show(model_id)
                .then((res) => {
                    this.form = JSON.parse(JSON.stringify(this.model));

                    if (this.form.clinic_id) {
                        this.visible_inn = true;
                        this.remoteMethod(parseInt(this.form.clinic_id));
                        this.filtered_rules = _.omit(this.filtered_rules, ['inn']);
                    } 
                    else{
                        this.visible_inn = false;
                    }

                    if (this.form.region_id && this.form.city_district_id) {
                        let query = {region_id: this.form.region_id, city_district_id: this.form.city_district_id}
                            this.getCityDistricts(query).then((res) => {this.city_districts = res.filteredCityDistricts});
                    }
                    if (this.form.work_region_id && this.form.work_city_id) {
                        let query = {region_id: this.form.work_region_id, city_district_id: this.form.work_city_id};
                        setTimeout(() => {this.getCityDistricts(query).then((res) => {this.work_city_districts = res.filteredCityDistricts});}, 500);     
                    }
                    
                    this.old_clinic_items = JSON.parse(JSON.stringify(this.old_items));
                    this.old_building_informations = JSON.parse(JSON.stringify(this.building_informations_of_clinics));
                })
                .catch((err) => {
                    console.log('err', err);
                });
            }
        },
        afterOpen(){
            this.load();
            this.setRules(this.rules);
        },

        ...mapActions({
            save: "clinics/update",
            show: "clinics/show",
        }),

        selectedClinicId(){
            this.visible_inn = true;
            this.clinics = [];
        },
        isLetter(e){
            let char = String.fromCharCode(e.keyCode); // Get the character
            if(/^[A-Za-z\s]+$/.test(char)) return true; // Match with regex 
            else e.preventDefault(); // If not match, don't add to input text
        },
        afterLeave(){
            this.$store.commit('clinics/EMPTY_MODEL');
            this.$refs['img_upload'].uploadFiles = [];
            this.old_clinic_items = [];
            this.old_building_informations = [];
            this.items = [];
            this.building_informations = [];
            this.filtered_rules = {};
        }
    },
    
};
</script>
<style lang="scss">
.delet-icon {
    font-size: 30px;
    position: absolute;
    right: -24px;
    top: 35px;
    color: red;
}
.result_inn {
    width: 50%;
    float: right;
}
.el-table .warning-row {
    background: oldlace;
}
</style>
