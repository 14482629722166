<template>
    <div class="internal-sections my-click" v-loading="loadingData">
        <el-row :gutter="20">
            <el-col :span="18">
                <h3>{{ $t("message.filials") }}</h3>
            </el-col>
            <!-- <el-col :span="6">
                <crm-create-and
                    @c-create="drawerCreate = true"
                    :columns="columns"
                    @c-change="updateColumn"
                >
                </crm-create-and>
            </el-col> -->
        </el-row>
        <el-tabs type="border-card" class="my-doctor-tabs mmi1">
            <el-tab-pane>
                <span slot="label" label="Config">
                    <i class="el-icon-c-scale-to-original"></i>
                </span>
                <div class="internal-sections-table">
                    <div
                        class="itme-clinics-branch"
                        v-for="(item, index) in filials"
                        :key="index"
                    >
                        <el-row :gutter="20">
                            <el-col :span="20">
                                <el-row :gutter="20">
                                    <el-col :span="8">
                                        <div class="itme-info-click">
                                            <b>Yuridik shaxsning nomi:</b>
                                            <span> {{ item.legal_entity_name }}</span>
                                        </div>
                                    </el-col>
                                    <!-- end col -->

                                    <el-col :span="8">
                                        <div class="itme-info-click">
                                            <b>Brend nomi:</b>
                                            <span> {{ item.brand_name }}</span>
                                        </div>
                                    </el-col>
                                    <!-- end col -->

                                    <el-col :span="8">
                                        <div class="itme-info-click">
                                            <b>Turi</b>
                                            <span>
                                                {{
                                                    item
                                                        .medical_organization_type_name
                                                }}
                                            </span>
                                        </div>
                                    </el-col>
                                    <!-- end col -->
                                    <el-col :span="24">
                                         <el-divider content-position="left">Tibbiy muassasaning yuridik manzili</el-divider>

                                    <el-col :span="8">
                                        <div class="itme-info-click">
                                            <b>Viloyat:</b>
                                            <span> {{ item.region ? item.region.region_name : '' }}  </span>
                                        </div>
                                    </el-col>
                                    <!-- end col -->
                                    <el-col :span="8">
                                        <div class="itme-info-click">
                                            <b>Shahar/Tuman:</b>
                                            <span> {{ item.city_district ? item.city_district.name : '' }} </span>
                                        </div>
                                    </el-col>
                                    <!-- end col -->
                                    </el-col>
                                   
                                    <el-col :span="24">
                                        <el-divider content-position="left">Tibbiy muassasaning faoliyat manzili</el-divider>

                                    <el-col :span="8">
                                        <div class="itme-info-click">
                                            <b>Viloyat:</b>
                                            <span> {{ item.work_region ? item.work_region.region_name : '' }}  </span>
                                        </div>
                                    </el-col>
                                    <!-- end col -->

                                    <el-col :span="8">
                                        <div class="itme-info-click">
                                            <b>Shahar/Tuman:</b>
                                            <span> {{ item.work_city_district ? item.work_city_district.name : '' }} </span>
                                        </div>
                                    </el-col>
                                    <!-- end col -->
                                    </el-col>
                                </el-row>
                            </el-col>

                            <el-col :span="3">
                                <div class="brench-logo">
                                    <img
                                        v-if="item.filial_logo.id"
                                        class="w-100-p"
                                        :src="url + '/' + item.filial_logo.path"
                                    />
                                    <img
                                        v-else
                                        class="w-100-p"
                                        src="./../../../public/img/avatar_clinica.jpg"
                                    />
                                </div>
                            </el-col>

                            <el-col :span="1">
                                <div class="branch-btn">
                                    <el-dropdown szie="mini" @command="handleCommand" class="table-bottons-right">
                                        <span class="el-dropdown-link more_icons">
                                            <i class="el-icon-open"></i>
                                        </span>
                                    <el-dropdown-menu slot="dropdown" size="mini" class="dropdown-menumy-style">
                                        <el-dropdown-item icon="el-icon-view">
                                            Профиль
                                        </el-dropdown-item>
                                        <el-dropdown-item command="edit" icon="el-icon-edit el-icon--left" v-show="noShow(item)">
                                            <span @click="edit(item)">Изменить</span>
                                        </el-dropdown-item>
                                        <!-- <el-dropdown-item command="delete" icon="el-icon-delete el-icon--left" v-show="noShow(item)">
                                            Удалить
                                        </el-dropdown-item> -->
                                    </el-dropdown-menu>
                                </el-dropdown>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </el-tab-pane>

            <el-tab-pane>
                <span slot="label" label="Config">
                    <span slot="label"><i class="el-icon-date"></i></span>
                </span>

                <div class="internal-sections-table">
                    <table
                        class="table-my-code table-bordered"
                        v-loading="loadingData"
                    >
                        <thead>
                            <tr>
                                <th class="w50p" v-if="columns.id.show">
                                    {{ columns.id.title }}
                                </th>

                                <th v-if="columns.filial_name.show">
                                    {{ columns.filial_name.title }}
                                </th>

                                <th v-if="columns.brand_name.show">
                                    {{ columns.brand_name.title }}
                                </th>

                                <th
                                    v-if="
                                        columns.medical_organization_type.show
                                    "
                                >
                                    {{
                                        columns.medical_organization_type.title
                                    }}
                                </th>

                                <th v-if="columns.created_at.show">
                                    {{ columns.created_at.title }}
                                </th>

                                <th v-if="columns.updated_at.show">
                                    {{ columns.updated_at.title }}
                                </th>

                                <th v-if="columns.settings.show">
                                    {{ columns.settings.title }}
                                </th>
                            </tr>

                            <tr class="filter_sorche">
                                <th v-if="columns.id.show">
                                    <el-input
                                        clearable
                                        size="mini"
                                        v-model="filterForm.id"
                                        :placeholder="columns.id.title"
                                        class="id_input"
                                    ></el-input>
                                </th>

                                <th v-if="columns.filial_name.show">
                                    <crm-input
                                        :placeholder="columns.filial_name.title"
                                        v-model="filterForm.filial_name"
                                    ></crm-input>
                                </th>

                                <th v-if="columns.brand_name.show">
                                    <crm-input
                                        :placeholder="columns.brand_name.title"
                                        v-model="filterForm.brand_name"
                                    ></crm-input>
                                </th>

                                <th
                                    v-if="
                                        columns.medical_organization_type.show
                                    "
                                >
                                    <select-organization-type
                                        v-model="
                                            filterForm.medical_organization_type
                                        "
                                        :size="'small'"
                                    >
                                    </select-organization-type>
                                </th>

                                <th v-if="columns.created_at.show">
                                    <crm-date-picker
                                        :placeholder="columns.created_at.title"
                                        v-model="filterForm.created_at"
                                    ></crm-date-picker>
                                </th>

                                <th v-if="columns.updated_at.show">
                                    <crm-date-picker
                                        :placeholder="columns.updated_at.title"
                                        v-model="filterForm.updated_at"
                                    ></crm-date-picker>
                                </th>

                                <th
                                    class="settinW"
                                    v-if="columns.settings.show"
                                ></th>
                            </tr>
                        </thead>

                        <transition-group name="flip-list" tag="tbody">
                            <tr
                                v-for="(filials, index) in list"
                                :key="index"
                                class="cursor-pointer"
                            >
                                <td v-if="columns.id.show">
                                    {{ filials.id }}
                                </td>

                                <td v-if="columns.filial_name.show">
                                    {{ filials.filial_name }}
                                </td>

                                <td v-if="columns.brand_name.show">
                                    {{ filials.brand_name }}
                                </td>

                                <td
                                    v-if="
                                        columns.medical_organization_type.show
                                    "
                                >
                                    {{
                                        filials.medical_organization_type
                                            ? filials.medical_organization_type
                                                  .name
                                            : ""
                                    }}
                                </td>

                                <td v-if="columns.created_at.show">
                                    {{ filials.created_at }}
                                </td>

                                <td v-if="columns.updated_at.show">
                                    {{ filials.updated_at }}
                                </td>

                                <td
                                    v-if="columns.settings.show"
                                    class="settings-td"
                                >
                                    <crm-settings
                                        :name="$options.name"
                                        :model="filials"
                                        :permissionShow="'filialsController@update'"
                                        :permissionDestroy="'filialsController@destroy'"
                                        :actions="actions"
                                        @edit="edit"
                                        @delete="destroy"
                                    ></crm-settings>
                                </td>
                            </tr>
                        </transition-group>
                    </table>
                    <div class="my-pagination">
                        <crm-pagination
                            @c-change="updatePagination"
                            :pagination="pagination"
                        ></crm-pagination>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>

        <div class="my-modal-big">
            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="drawerCreate"
                :wrapperClosable="false"
                size="95%"
                ref="drawerCreate"
                @opened="drawerOpened('drawerCreateChild')"
                @closed="drawerClosed('drawerCreateChild')"
            >
                <crm-create
                    ref="drawerCreateChild"
                    drawer="drawerCreate"
                    :status="true"
                ></crm-create>
            </el-drawer>

            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="drawerUpdate"
                :wrapperClosable="false"
                size="95%"
                ref="drawerUpdate"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')"
            >
                <crm-update
                    :selectedItem="selectedItem"
                    :status="false"
                    ref="drawerUpdateChild"
                    drawer="drawerUpdate"
                ></crm-update>
            </el-drawer>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
import _ from 'lodash';
import list from "@/utils/mixins/models/listForModels";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import { i18n } from "@/utils/i18n";

export default {
    name: "filials",
    mixins: [list],
    components: {
        CrmCreate,
        CrmUpdate,
    },
    data() {
        return {
            url: process.env.VUE_APP_URL_DOCS,
            filials: [],
            filial_logo: {},
        };
    },
    computed: {
        ...mapGetters({
            list: "filials/list",
            columns: "filials/columns",
            pagination: "filials/pagination",
            statues: "filials/statues",
            filter: "filials/filter",
            sort: "filials/sort",
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "filials/filteredFilialsByClinicId",
            setPagination: "filials/setPagination",
            updateSort: "filials/updateSort",
            updateFilter: "filials/updateFilter",
            updateColumn: "filials/updateColumn",
            updatePagination: "filials/updatePagination",
            show: "filials/show",
            empty: "filials/empty",
            delete: "filials/destroy",
            refreshData: "filials/refreshData",
        }),
          fetchData(){ 
            this.filials = [];
            if (this.$route.params.hasOwnProperty('id')) {
                let path = this.$route.path.substring(1,13);
                this.model_id = (path === 'officeClinic') ? parseFloat(this.$route.params.id) : null;
            }else{
                this.model_id = null;
            }
            
            const query = {
                ...this.filter,
                ...this.pagination,
                ...this.sort,
                'model_id': this.model_id
            };
            if (!this.loadingData) {
                this.loadingData = true;
                this.updateList(query).then(res => {
                    this.loadingData = false;
                    this.setGroupedFilialData(res.filials)
                }).catch(err => {
                    this.loadingData = false;
                });
            }
        },
        setGroupedFilialData(filials){
            if (filials && !_.isEmpty(filials)){
                filials.forEach((filial, index) => {
                    if (filial.hasOwnProperty('images') && !_.isEmpty(filial.images)) {
                        this.filial_logo['id'] = filial.images[0].id;
                        this.filial_logo['path'] = filial.images[0].path;
                    }else{
                        this.filial_logo['id']  = null;
                        this.filial_logo['path'] = null;
                    }

                    this.filials.push({
                        id: filial.id,
                        legal_entity_name: filial.legal_entity_name,
                        brand_name: filial.brand_name,
                        medical_organization_type_name: filial.medical_organization_type_name,
                        email: filial.email,
                        region: filial.region,
                        city_district: filial.city_district,
                        work_region: filial.work_region,
                        work_city_district: filial.work_city_district,
                        address: filial.address,
                        filial_logo: this.filial_logo,
                        user_id: filial.user_id,
                        current_user_id: filial.current_user_id,
                        status: filial.status
                    });

                    this.filial_logo = {};
                });
                
            }
        },
        handleCommand(command) {
            if (command === "delete") {
                this.$confirm(
                    "Вы действительно хотите это сделать?",
                    "Предупреждение",
                    {
                        confirmButtonText: "Да",
                        cancelButtonText: "Отмен",
                        type: "warning",
                    }
                )
                    .then(() => {
                        this.$emit(command, this.model);
                    })
                    .catch(() => {
                        this.$message({
                            type: "warning",
                            message: "Операция отменена",
                        });
                    });
            } else {
                this.$emit(command, this.model);
            }
        },
        noShow(item) {
            if (item.status) {
                return true;
            }
            else{
                let status = (item.user_id == item.current_user_id) ? true : false;
                return status;
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("filials/EMPTY_LIST");
        next();
    },
};
</script>
<style lang="scss">
.mmi1.my-doctor-tabs .el-tabs__item {
    margin-bottom: 10px;
    font-size: 22px;
    background: var(--card-color);
    border: none !important;
    background-color: #0087af !important;
    margin: 0px 5px;
    border-radius: 4px !important;
    color: #fff !important;
}
.mmi1.my-doctor-tabs .el-tabs__nav-wrap {
    margin-top: -57px;
}
</style>
